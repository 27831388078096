#register {
    .login-outerholder {
      margin: auto; 
      margin-bottom: 8em;   
    }
  
  
    .login-holder {
      text-align: center;
      padding-bottom: 0em;
    }
  
    .headlineOne {
      padding: 1em;
      font-size: 1.5em;
      color: @background;
    }
  
    .section {
      padding: 1em 0;
    }
  
    .input-field {
      position: relative;
      color: black;
      width: 20em;
    }
      
    .label{
      @media (max-width : 600px) {
        vertical-align: text-top;
      }
    }

    .actions{
      margin-top: 1em;
    }
  }