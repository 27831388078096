header {
  .logo {
    font-size: 1.4em;
    margin-left: 2em;

    @media (max-width : 1000px) {
      font-size: 1.2em;
      margin-left: 2em;
    }
  }
  
  .logo-red{
    color: @accent;
    font-weight: bold;
    text-shadow: rgba(0, 0, 0, 0.4) 0px 4px 5px;
    margin-top: .7em;
  }

  .logo-icon {
    margin-top: .7em;
    
    img {
      height: 1.4em;
      border-radius: 10em;
    }
  }
  
  .logo-white{
    color: white;
    margin-left: .2em;
  }

  .product-title{
    color: @headlines;
    margin-left: 1em;
    margin-top: .7em;
  }

  .wrapper{
    justify-content: space-between;
    width: 100%;
    background-color: @background;
    height: 6em;

    @media (max-width : 1000px) {
      height: 4em;
    }
  }

  .need-help{
    color: white;
    margin-top: 2.3em;
    margin-right: 1em;
    cursor: pointer;

    @media (max-width : 1000px) {
      margin-top: 1.3em;
    }
  }

  .need-help:hover{
    color: @buttonHoverBackground;
  }
  
  .btn-logout {
    border: solid black 0.1em;
    padding: .5em 1em;
    color: black;
    margin: auto;
    width: 75px;
    text-align: center;
    padding: .5em;
    cursor: pointer;
    text-decoration: none;
    background: @headlines;
    float: right;
    margin-top: 1.8em;
    margin-right: 3em;

    @media only screen and (max-width : 1000px) {
      display: none;
    }
  }

  .avatar{
    margin-top: 1.8em;
    margin-right: 1em;

    @media only screen and (max-width : 1000px) {
      display: none;
    }
  }
}

.support-dialog{
  padding: 0 2em 2em 2em;
  text-align: center;

  .support-dialog-email{
    margin-top: 1em;
  }

  .support-links-container{
    margin-top: 1em;
  }

  .support-links {
    list-style-type: none;
    padding: 0;
  }

  .header{
    font-weight: bold;
  }
}
