#loader {
    display: inline-block;
    width: 100%;

    .load-icon{
        margin-left:auto;
        margin-right:auto;
        text-align: center;
        margin-top: 2em;
        padding: 2em;
        font-size: 4em;
    
    }
}