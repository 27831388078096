#home{
    .systems-completable-complete{
        margin-left: 4.5em;
        margin-top: 1em;
    }

    .system-links {
        justify-content: space-evenly;
        
    }

    .system-link-container{
        width: 15%;
        min-width: 10em;
        margin: 0 1em;

        @media (max-width : 1000px) {
            margin-bottom: 3em;
        }
    }
    .system-link-container-one-row{
        width: 20%;
        min-width: 13em;
    }

    
    .system-link {
        margin-top: 2em;
        color: @notPurchased;
        cursor: default;
        text-align: center;
        position: relative;
    }

    .system-link-icon-container{
        text-align: center;
        width: 100%;
    }
    .system-link-icon{
        font-size: 5em;
        position: relative;

    }

    .system-link-name{
        text-align: center;
        font-size: 1.5em;
        margin-top: .2em;
    }

    .completed {
        color: @completed;
        font-size: 0.3em;       
        position: absolute;
    }

    .notcomplete{
        margin-top: 1em;
    }

    .download-cert-container{
        margin-left: 1em;
        margin-top: 1em;
    }

    .system-link.purchased{
        color: black;
        cursor: pointer;
    }

    .system-link.purchased:hover{
        color: @hoverColor;
    }

    .tool-tip p{
        color: white;
    }

    .tool-tip a {
        color: white;
    }

    h2 {
        font-size: 1.5em;
        margin:0;
    }

    .paper {
        margin-bottom: 1em;
        padding-bottom: 3em;
    }

    .showProductsLink{
        cursor: pointer;
    }


}
