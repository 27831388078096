#cookie-policy {
    .cookie-policy {
        position: fixed;
        bottom: 0;
        color: white;
        background: #989797;
        padding: 1em;
        z-index: 1;
    }

    .cookie-policy-text{
        width: 88%;
        display: inline;
    }

}