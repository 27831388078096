#app-error {
    display: inline-block;
    width: 100%;

    .error-message{
        margin-left:auto;
        margin-right:auto;
        text-align: center;
        margin-top: 10em;
        width: 30%;
        background-color: @error;
        padding: 2em;
    
    }
}