#changePassword{  
      margin: auto; 
      margin-bottom: 8em;   
      text-align: center;
      padding-bottom: 0em;

      .box {
          max-width: 40em;;
          margin: auto;
          padding-top: 2em;
          padding-bottom: 2em;
          text-align: center;
      } 

      .headlineOne {
        padding: 1em;
        font-size: 1.5em;
        color: @background;
      }
    
      .section {
        padding: 1em 0;
      }
    
      .input-field {
        position: relative;
        color: black;
        width: 60%;
      }
        
      .label{
        @media (max-width : 600px) {
          vertical-align: text-top;
        }
      }
  
      .actions{
        margin-top: 1em;
      }


}