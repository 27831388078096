.MuiButton-outlinedPrimary{
    color: black !important;
}

.MuiButton-outlinedPrimary:hover{
    color: white !important;
}

.MuiInputLabel-root{
    background-color: white;
}

.MuiFab-primary {
    background-color: @buttonHoverBackground !important;
  }

  button[class*="MuiButton-root"]:hover {
    color: white;
    background: @buttonHoverBackground !important;
}

button[class*="MuiButton-root"].button-link:hover{
    background: white !important;
  }

.MuiInput-root input[type="date"]{
    margin-top: 1em !important;
}

.MuiCircularProgress-colorPrimary{
    color: @buttonHoverBackground !important;;
}

.MuiPaper-root{
    padding: 1em;
}

button{
    .material-icons{
        margin-right: .2em;
    }
}