#learning{
    margin-top: 1em;

    .resourceCategory{
        margin-bottom: 2em;
    }
    .resources{
        margin-left: 2em;
    }
    .resource{
        margin-bottom: .4em;
    }

    h2 {
        margin-top: .1em;
        margin-bottom: .3em;
    }

    .videoLink, .documentLink{
        cursor: pointer;
    }

    .actions{
        margin-top: 1em;
        margin-left: 1em;
    }
}

.learning .MuiDialog-paper{
    min-width: 77em;
    min-height: 30em;
}

.vimeo-video{
    width: 100%;
    width: 20em;
    height: 20em;

    .vimeo-loading{
        display: none;
    }

    .vimeo-embed{
        width: 100%;
    }

    iframe {
        min-width: 75em;
        min-height: 30em;

        @media (max-width : 1000px) {
            min-width: 20em;
            min-height: 20em;
          }

          @media (max-width : 1600px) {
            min-width: 56em;
            margin-top: 3em;
          }
    }
}