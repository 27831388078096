#login {
  .login-outerholder {
    margin: auto;
    margin-bottom: 8em;
  }


  .login-holder {
    text-align: center;
    padding-bottom: 0em;
  }

  .headlineOne {
    padding-top: 1em;
    font-size: 1.5em;
    color: @background;
  }


  .login-box {
    max-width: 40em;;
    margin: auto;
    padding-top: 2em;
    padding-bottom: 2em;
    text-align: center;
  }

  .section{
    margin-top: 1em;
  }

  .btn-holder {
    margin-top: 1em;
  }

  .input-field {
    min-width: 60%;
  }

  a {
   color: white;
  }

  a:link {
   color: white;
  }

  .errorMessage{
    margin-top: 1em;
    color:@error;
  }

  .or{
    margin:2em 2em;
    font-weight: bold;
  }

  .button-link{
    margin-top: .5em;
    font-size: .8em;
    color: @sublecolor;
  }


  .send-invite-link{
    background-color: white;
  }

  .send-invite-link:hover{
    color: black;
  }
  .oauth2-block {
    display: flex;
    justify-content: space-around;
    margin-top: 15px
  }

  .oauth2-block a {
    padding: 16px;
    width: 50%;
    display: flex;
    justify-content: center;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.2) 0 2px
  }

  .oauth2-block a:hover {
    box-shadow: none;
  }

  .oauth2-block a:first-child {
    margin-left: 0;
  }

  .oauth2-block a:last-child {
    margin-right: 0;
  }

  .oauth2-block a img {
    height: 28px
  }
}
