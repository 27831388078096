@import "./color";
@import "./Material";
@import "./Header";
@import "./CookiePolicy";
@import "./Message";
@import "./AppError";
@import "./Loader";
@import "./Login";
@import "./VerifyEmail";
@import "./Register";
@import "./Home";
@import "./learning";
@import "./ForgotPassword";
@import "./ChangePassword";


a {
  text-decoration: none;
  color:black;
}


a:hover {
  color: @hoverColor;
  text-decoration: none !important;
}


html
{
  min-height: 100%;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body
{
  background: @frontground;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}

html,
body {
  height: 100%;
  margin: 0;
}

.flex {
  display: flex
}

.flex-row{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.flex-col {
  display: flex;
  flex-direction: column
}

.flex-center {
  display: flex;
  justify-content: center;
}

.incompatibleBrowser{
  font-weight: bold;
  margin: 2em;
}

.content {
  min-height: 100% !important;
  width: 100%;
}

.copyright {
  margin: auto;
  padding-top: 5.3em;
  width: 600px;
  text-align: center;
}

  .main-container {
    min-height: 50em;
    padding-bottom: 5em;
    background: @frontground;
    border: 0px solid #000000;

    @media (max-width : 1000px) {
      margin-left: 0;
      padding-bottom: 2em;
    }

  }

  h1 {
   font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
   font-weight: 300;
   color: @textcolor;
   font-size: 3em;
  }

  h2 {
   font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
   font-weight: 300;
   color: @textcolor;
  }

  p {
    padding: .25em 2em 1em 0em;
    color: @textcolor;
    line-height: 1.5em;
    letter-spacing: .1em;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 

  }

  .main-content{
        background: @frontground;
        height: 100%;
        border-radius: 14px;
        border: 0px solid #000000;
        padding: 2em;
  }


  .successMessage{
    font-weight: bold;
    color: @success;
  }

  .errorMessage{
    font-weight: bold;
    color: @headlines;
  }
  
