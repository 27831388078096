.MuiButton-outlinedPrimary {
  color: black !important;
}
.MuiButton-outlinedPrimary:hover {
  color: white !important;
}
.MuiInputLabel-root {
  background-color: white;
}
.MuiFab-primary {
  background-color: #a90004 !important;
}
button[class*="MuiButton-root"]:hover {
  color: white;
  background: #a90004 !important;
}
button[class*="MuiButton-root"].button-link:hover {
  background: white !important;
}
.MuiInput-root input[type="date"] {
  margin-top: 1em !important;
}
.MuiCircularProgress-colorPrimary {
  color: #a90004 !important;
}
.MuiPaper-root {
  padding: 1em;
}
button .material-icons {
  margin-right: 0.2em;
}
header .logo {
  font-size: 1.4em;
  margin-left: 2em;
}
@media (max-width: 1000px) {
  header .logo {
    font-size: 1.2em;
    margin-left: 2em;
  }
}
header .logo-red {
  color: #f8f2ee;
  font-weight: bold;
  text-shadow: rgba(0, 0, 0, 0.4) 0px 4px 5px;
  margin-top: 0.7em;
}
header .logo-icon {
  margin-top: 0.7em;
}
header .logo-icon img {
  height: 1.4em;
  border-radius: 10em;
}
header .logo-white {
  color: white;
  margin-left: 0.2em;
}
header .product-title {
  color: #f8f2ee;
  margin-left: 1em;
  margin-top: 0.7em;
}
header .wrapper {
  justify-content: space-between;
  width: 100%;
  background-color: #3f3f3f;
  height: 6em;
}
@media (max-width: 1000px) {
  header .wrapper {
    height: 4em;
  }
}
header .need-help {
  color: white;
  margin-top: 2.3em;
  margin-right: 1em;
  cursor: pointer;
}
@media (max-width: 1000px) {
  header .need-help {
    margin-top: 1.3em;
  }
}
header .need-help:hover {
  color: #a90004;
}
header .btn-logout {
  border: solid black 0.1em;
  padding: 0.5em 1em;
  color: black;
  margin: auto;
  width: 75px;
  text-align: center;
  padding: 0.5em;
  cursor: pointer;
  text-decoration: none;
  background: #f8f2ee;
  float: right;
  margin-top: 1.8em;
  margin-right: 3em;
}
@media only screen and (max-width: 1000px) {
  header .btn-logout {
    display: none;
  }
}
header .avatar {
  margin-top: 1.8em;
  margin-right: 1em;
}
@media only screen and (max-width: 1000px) {
  header .avatar {
    display: none;
  }
}
.support-dialog {
  padding: 0 2em 2em 2em;
  text-align: center;
}
.support-dialog .support-dialog-email {
  margin-top: 1em;
}
.support-dialog .support-links-container {
  margin-top: 1em;
}
.support-dialog .support-links {
  list-style-type: none;
  padding: 0;
}
.support-dialog .header {
  font-weight: bold;
}
#cookie-policy .cookie-policy {
  position: fixed;
  bottom: 0;
  color: white;
  background: #989797;
  padding: 1em;
  z-index: 1;
}
#cookie-policy .cookie-policy-text {
  width: 88%;
  display: inline;
}
#toast {
  position: fixed;
  top: 7em;
  width: 30em;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  opacity: 0.95;
  z-index: 99;
}
#toast .title {
  font-size: 1.2em;
  font-weight: bold;
  display: inline;
  margin-right: 1em;
}
#toast .toast {
  padding: 1em;
}
#toast .success {
  background-color: #61b961;
}
#toast .error {
  background-color: #ce696b;
}
#toast .message {
  display: inline;
}
#toast .close-button {
  position: absolute;
  top: 0.1em;
  right: 0.2em;
  opacity: 0.6;
}
#toast .fa {
  color: white;
  font-size: 1.2em;
}
#app-error {
  display: inline-block;
  width: 100%;
}
#app-error .error-message {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 10em;
  width: 30%;
  background-color: #ce696b;
  padding: 2em;
}
#loader {
  display: inline-block;
  width: 100%;
}
#loader .load-icon {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 2em;
  padding: 2em;
  font-size: 4em;
}
#login .login-outerholder {
  margin: auto;
  margin-bottom: 8em;
}
#login .login-holder {
  text-align: center;
  padding-bottom: 0em;
}
#login .headlineOne {
  padding-top: 1em;
  font-size: 1.5em;
  color: #3f3f3f;
}
#login .login-box {
  max-width: 40em;
  margin: auto;
  padding-top: 2em;
  padding-bottom: 2em;
  text-align: center;
}
#login .section {
  margin-top: 1em;
}
#login .btn-holder {
  margin-top: 1em;
}
#login .input-field {
  min-width: 60%;
}
#login a {
  color: white;
}
#login a:link {
  color: white;
}
#login .errorMessage {
  margin-top: 1em;
  color: #ce696b;
}
#login .or {
  margin: 2em 2em;
  font-weight: bold;
}
#login .button-link {
  margin-top: 0.5em;
  font-size: 0.8em;
  color: gray;
}
#login .send-invite-link {
  background-color: white;
}
#login .send-invite-link:hover {
  color: black;
}
#login .oauth2-block {
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
}
#login .oauth2-block a {
  padding: 16px;
  width: 50%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px;
}
#login .oauth2-block a:hover {
  box-shadow: none;
}
#login .oauth2-block a:first-child {
  margin-left: 0;
}
#login .oauth2-block a:last-child {
  margin-right: 0;
}
#login .oauth2-block a img {
  height: 28px;
}
#verifyEmail .login-box {
  max-width: 40em;
  margin: auto;
  padding-top: 2em;
  padding-bottom: 2em;
  text-align: center;
}
#register .login-outerholder {
  margin: auto;
  margin-bottom: 8em;
}
#register .login-holder {
  text-align: center;
  padding-bottom: 0em;
}
#register .headlineOne {
  padding: 1em;
  font-size: 1.5em;
  color: #3f3f3f;
}
#register .section {
  padding: 1em 0;
}
#register .input-field {
  position: relative;
  color: black;
  width: 20em;
}
@media (max-width: 600px) {
  #register .label {
    vertical-align: text-top;
  }
}
#register .actions {
  margin-top: 1em;
}
#home .systems-completable-complete {
  margin-left: 4.5em;
  margin-top: 1em;
}
#home .system-links {
  justify-content: space-evenly;
}
#home .system-link-container {
  width: 15%;
  min-width: 10em;
  margin: 0 1em;
}
@media (max-width: 1000px) {
  #home .system-link-container {
    margin-bottom: 3em;
  }
}
#home .system-link-container-one-row {
  width: 20%;
  min-width: 13em;
}
#home .system-link {
  margin-top: 2em;
  color: #969696;
  cursor: default;
  text-align: center;
  position: relative;
}
#home .system-link-icon-container {
  text-align: center;
  width: 100%;
}
#home .system-link-icon {
  font-size: 5em;
  position: relative;
}
#home .system-link-name {
  text-align: center;
  font-size: 1.5em;
  margin-top: 0.2em;
}
#home .completed {
  color: #288028;
  font-size: 0.3em;
  position: absolute;
}
#home .notcomplete {
  margin-top: 1em;
}
#home .download-cert-container {
  margin-left: 1em;
  margin-top: 1em;
}
#home .system-link.purchased {
  color: black;
  cursor: pointer;
}
#home .system-link.purchased:hover {
  color: #585858;
}
#home .tool-tip p {
  color: white;
}
#home .tool-tip a {
  color: white;
}
#home h2 {
  font-size: 1.5em;
  margin: 0;
}
#home .paper {
  margin-bottom: 1em;
  padding-bottom: 3em;
}
#home .showProductsLink {
  cursor: pointer;
}
#learning {
  margin-top: 1em;
}
#learning .resourceCategory {
  margin-bottom: 2em;
}
#learning .resources {
  margin-left: 2em;
}
#learning .resource {
  margin-bottom: 0.4em;
}
#learning h2 {
  margin-top: 0.1em;
  margin-bottom: 0.3em;
}
#learning .videoLink,
#learning .documentLink {
  cursor: pointer;
}
#learning .actions {
  margin-top: 1em;
  margin-left: 1em;
}
.learning .MuiDialog-paper {
  min-width: 77em;
  min-height: 30em;
}
.vimeo-video {
  width: 100%;
  width: 20em;
  height: 20em;
}
.vimeo-video .vimeo-loading {
  display: none;
}
.vimeo-video .vimeo-embed {
  width: 100%;
}
.vimeo-video iframe {
  min-width: 75em;
  min-height: 30em;
}
@media (max-width: 1000px) {
  .vimeo-video iframe {
    min-width: 20em;
    min-height: 20em;
  }
}
@media (max-width: 1600px) {
  .vimeo-video iframe {
    min-width: 56em;
    margin-top: 3em;
  }
}
#forgotPassword {
  margin: auto;
  margin-bottom: 8em;
  text-align: center;
  padding-bottom: 0em;
}
#forgotPassword .box {
  max-width: 40em;
  margin: auto;
  padding-top: 2em;
  padding-bottom: 2em;
  text-align: center;
}
#forgotPassword .headlineOne {
  padding: 1em;
  font-size: 1.5em;
  color: #3f3f3f;
}
#forgotPassword .section {
  padding: 1em 0;
}
#forgotPassword .input-field {
  position: relative;
  color: black;
  width: 60%;
}
@media (max-width: 600px) {
  #forgotPassword .label {
    vertical-align: text-top;
  }
}
#forgotPassword .actions {
  margin-top: 1em;
}
#forgotPassword .errorMessage {
  margin-top: 1em;
  color: #ce696b;
}
#changePassword {
  margin: auto;
  margin-bottom: 8em;
  text-align: center;
  padding-bottom: 0em;
}
#changePassword .box {
  max-width: 40em;
  margin: auto;
  padding-top: 2em;
  padding-bottom: 2em;
  text-align: center;
}
#changePassword .headlineOne {
  padding: 1em;
  font-size: 1.5em;
  color: #3f3f3f;
}
#changePassword .section {
  padding: 1em 0;
}
#changePassword .input-field {
  position: relative;
  color: black;
  width: 60%;
}
@media (max-width: 600px) {
  #changePassword .label {
    vertical-align: text-top;
  }
}
#changePassword .actions {
  margin-top: 1em;
}
a {
  text-decoration: none;
  color: black;
}
a:hover {
  color: #585858;
  text-decoration: none !important;
}
html {
  min-height: 100%;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
body {
  background: #f5f5f5;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}
html,
body {
  height: 100%;
  margin: 0;
}
.flex {
  display: flex;
}
.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-center {
  display: flex;
  justify-content: center;
}
.incompatibleBrowser {
  font-weight: bold;
  margin: 2em;
}
.content {
  min-height: 100% !important;
  width: 100%;
}
.copyright {
  margin: auto;
  padding-top: 5.3em;
  width: 600px;
  text-align: center;
}
.main-container {
  min-height: 50em;
  padding-bottom: 5em;
  background: #f5f5f5;
  border: 0px solid #000000;
}
@media (max-width: 1000px) {
  .main-container {
    margin-left: 0;
    padding-bottom: 2em;
  }
}
h1 {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 300;
  color: black;
  font-size: 3em;
}
h2 {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 300;
  color: black;
}
p {
  padding: 0.25em 2em 1em 0em;
  color: black;
  line-height: 1.5em;
  letter-spacing: 0.1em;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}
.main-content {
  background: #f5f5f5;
  height: 100%;
  border-radius: 14px;
  border: 0px solid #000000;
  padding: 2em;
}
.successMessage {
  font-weight: bold;
  color: #61b961;
}
.errorMessage {
  font-weight: bold;
  color: #f8f2ee;
}
